function scrollTo($element) { 
	  // settings
	  var hashOffset = 80
	  var scrollVelocity = 1.5;
	  
	  var hashHeight = $element.offset().top;
	  var totalHeight = $(document).height();

	  var distanceToHash = totalHeight - hashHeight;

	  var scrollDuration = distanceToHash/scrollVelocity;

	  $('html, body' ).animate({
	    scrollTop: hashHeight - hashOffset
	  }, scrollDuration);
};

