/*requires:
scrollTo.js
*/

// function masonry() {
//   // var colWidth = $('.grid-item').width();

// 	$('#row').masonry({
// 	  itemSelector: '.col-md-6',
// 	});
// }

function Calcheight() {
	var heightDoc = $(document).height();
	var heightWin = $(window).height();
	// console.log(heightDoc);
	// console.log(heightWin+600);
	if (heightDoc < heightWin +600) {
		$('.scroll-top').hide();
	}
	else {
		$('.scroll-top').show();

	}
}

$(document).ready(function() {
	Calcheight();
	$('.scroll-top').click(function() {
		var aTag = $("header .red-area");
		$('html, body').animate({scrollTop: aTag.offset().top}, 1000, "linear");
	});

	$('.icon-menu').click(function() {
		if ($('.menu-mobile').hasClass("active")) {
			$('.menu-mobile').removeClass("active");
		}
		else {
			$('.menu-mobile').addClass("active");
		}
	});

	$('.dropdown').on('show.bs.dropdown', function(e){
	   $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
	 });

	 // ADD SLIDEUP ANIMATION TO DROPDOWN //
	 $('.dropdown').on('hide.bs.dropdown', function(e){
	   $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
	 });

	 //Input Radios
	//  $('label:has(input[type="radio"])').addClass("style");
	//  $('label:has(input[type="checkbox"])').addClass("styleb");

	//  $('input[type="radio"]').each(function() { 
	// 	 if ($(this).is(':checked')) {
	// 	 	console.log("true");
	// 	 	$(this).parent().addClass("style-active");
	// 	 }
	// 	 else {
	// 	 	console.log("false");
	// 	 	$(this).parent().removeClass("style-active");

	// 	 }
	//  });
	 
	// $('label.style').click(function() {
	//  	$('label.style').removeClass("style-active");
	//  	$(this).addClass("style-active");
	// });
 //  $('label.styleb').click(function() {
 //  	if ($(this).hasClass('style-active')) {
 //  		$(this).removeClass("style-active");
 //  		return false;
 //  	}
 //  	else {
 //  		$(this).addClass("style-active");
 //  		return false;

 //  	}
 // });
	 // $('label:has(input[type="radio"]:checked)').addClass("style-active");
	 //end input radius
	 $('.search-img').click(function(){
	 	if ($('.search').hasClass("open")) {
	 		$('.search').removeClass("open");
	 		$('.menu-top > span:nth-of-type(1)').removeClass("hide-line");
	 	}
	 	else {
	 		$('.search').addClass("open");
	 		$('.menu-top > span:nth-of-type(1)').addClass("hide-line");
	 	}
	 });

	 $(window).click(function() {
	 	$('.search').removeClass("open");
	 });

	 $('.search').click(function(event){
	     event.stopPropagation();
	 });
	 //input number
	 (function() {
	  
	   window.inputNumber = function(el) {

	     var min = el.attr('min') || false;
	     var max = el.attr('max') || false;

	     var els = {};

	     els.dec = el.prev();
	     els.inc = el.next();

	     el.each(function() {
	       init($(this));
	     });

	     function init(el) {

	       els.dec.on('click', decrement);
	       els.inc.on('click', increment);

	       function decrement() {
	         var value = el[0].value;
	         value--;
	         if(!min || value >= min) {
	           el[0].value = value;
	         }
	       }

	       function increment() {
	         var value = el[0].value;
	         value++;
	         if(!max || value <= max) {
	           el[0].value = value++;
	         }
	       }
	     }
	   }
	 })();

	 inputNumber($('.input-number'));
	 // end input number
	
});

$( window ).scroll(function() {
 if ($(document).scrollTop() > 500)  {
   $('.scroll-top').addClass('active');
  
  }
  else {
    $('.scroll-top').removeClass('active');
  
  }
  var heightWind = $(window).height();
  var heightDoc = $(document).height() - heightWind - 413;

  if ($(document).scrollTop() > heightDoc)  {
   $('.scroll-top').addClass('stop');
  
  }
  else {
    $('.scroll-top').removeClass('stop');
  }
}); 


$(window).load(function() {
/*	masonry();
*/});

$(window).resize(function() {
	Calcheight();
});

$('a[href*="#_"]').on('click', function(e){
	e.preventDefault();
	var $destinationElement = $(this.hash)

	if ($destinationElement.length > 0) {
		scrollTo($destinationElement);
	} else {
		// just do what a regular anchor would, if there's no element to go to!
		window.location = this.href;
	}
});